import { Injectable } from '@angular/core';
import {RestApiService} from "@makeo-packages/mkongtools";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ChatPdfService {

  apiEndpoint: string = 'chat';

  constructor(protected api: RestApiService) {}

  public getAnswer(data: any): Observable<any>{
    return this.api.post(this.apiEndpoint, data).pipe(map(res => {
      return res.datas;
    }));
  }

}

import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {CustomButtonsLoginComponent} from "./views/custom-buttons-login/custom-buttons-login.component";
import {AuthService, CustomLoginButtonsTemplateService, CustomLogoTemplateService} from "@makeo-packages/mkongusers";
import {CustomLogoLoginComponent} from "./views/custom-logo-login/custom-logo-login.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'front-ia-documentaire';
  constructor(
    private translateService: TranslateService,
    private customButtons: CustomLoginButtonsTemplateService,
    private customLogo: CustomLogoTemplateService,
    private router: Router,
    private authService: AuthService
  ) {
    this.translateService.addLangs(['fr', 'en']);
    this.translateService.setDefaultLang('fr');
    this.translateService.use('fr');

    this.authService.signOutEvent.subscribe(()=>{
      this.router.navigate(['/auth/login']);
    })
    customButtons.setCustomTemplate(CustomButtonsLoginComponent);
    customLogo.setCustomTemplate(CustomLogoLoginComponent);
  }
}

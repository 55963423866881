import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {MkButtonComponent, MkongtoolsModule, ErrorHandlerService} from "@makeo-packages/mkongtools";
import {environment} from "../environments/environment";
import {AuthInterceptor, MkFormBuilderComponent, MkongusersModule} from "@makeo-packages/mkongusers";
import {LayoutComponent} from './layout/layout.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ChatPdfModule} from "./views/chat-pdf/chat-pdf.module";
import {MatStepperModule} from "@angular/material/stepper";
import {MatButtonModule} from "@angular/material/button";
import {GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig} from '@abacritt/angularx-social-login';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {CustomButtonsLoginComponent} from './views/custom-buttons-login/custom-buttons-login.component';
import * as Sentry from "@sentry/angular-ivy";
import {Router} from "@angular/router";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    CustomButtonsLoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MkongtoolsModule,
    MkongusersModule,
    MkFormBuilderComponent,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    ChatPdfModule,
    MatStepperModule,
    MatButtonModule,
    GoogleSigninButtonModule,
    TranslateModule.forRoot({
//defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MkButtonComponent,
    NgOptimizedImage,

  ],
  providers: [
    // {provide: HTTP_INTERCEPTORS, useClass:  multi: true}, // interc
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('475275581265-lgtcak7t98fsp42vqmouv5d3hfpc423u.apps.googleusercontent.com'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: 'baseUrl', useValue: environment.apiAdress,
    },
    {
      provide: 'debounceTime', useValue: environment.debounceTime || 1
    },
    {
      provide: 'isMailLogin', useValue: environment.isMailLogin || true
    },
    {
      provide: 'isRemenberMe', useValue: environment.isRemenberMe || false
    },
    {
      provide: 'isAccesFormRegister', useValue: environment.isAccesFormRegister || true
    },
    {
      provide: 'isAccesFormForgotPassword', useValue: environment.isAccesFormForgotPassword || true
    },
    {
      provide: 'patternPasswordValidator', useValue: environment.patternPasswordValidator || ''
    },
    {
      provide: 'descriptionPatternPasswordValidator', useValue: environment.descriptionPatternPasswordValidator || ''
    },
    {
      provide: 'refreshTokenTtl', useValue: environment.refreshTokenTtl || 60
    },
    {
      provide: 'domain', useValue: environment.domain || ''
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

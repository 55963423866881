import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from "@sentry/angular" // for Angular 10/11 instead
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/app.module';
import {environment} from "./environments/environment";

Sentry.init({
  dsn: "https://fc15dee786f6483791a6993a2961c2cc@sentry.staging.makeo.fr/11",
  environment: environment.sentry_env,
  enabled: environment.sentry_enabled,

  // integrations: [
  //   new Sentry.BrowserTracing({
  //     tracePropagationTargets: ["localhost", "https://yourserver.io/api"],
  //     routingInstrumentation: Sentry.routingInstrumentation,
  //   }),
  // ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

import {Component, OnInit} from '@angular/core';
import {AuthService, UserService} from "@makeo-packages/mkongusers";
import {Router} from "@angular/router";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  private currentUserData: any;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    public router: Router
  ) {
  }

  ngOnInit() {
    this.userService.getUserDatas();
    this.userService.userSubject.subscribe(() => {
      this.currentUserData = this.userService.getUser();
    })
  }

  getCurrentUserName(): string {
    if (this.currentUserData?.firstname && this.currentUserData?.lastname) {
      return this.currentUserData?.firstname + ' ' + this.currentUserData?.lastname;
    }
    else if (this.currentUserData?.username) {
      return this.currentUserData?.username;
    }
    else return "";
  }

  logout() {
    this.authService.signOut();
  }

  currentRouteIsChat(): boolean {
    return this.router?.url.includes('chat');
  }
}

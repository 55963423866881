import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ChatPdfComponent} from "./chat-pdf/chat-pdf.component";

const routes: Routes = [
  {
    path: ':id',
    component: ChatPdfComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatPdfRoutingModule { }

export const environment = {
  production: true,
  apiAdress: 'https://api.waterdoctor.ai/api',
  pdfAdress: 'https://api.waterdoctor.ai/pdf',
  debounceTime: 1,
  isMailLogin: true,
  refreshTokenTtl: 60,
  domain: 'waterdoctor.ai',
  isRemenberMe: true,
  isAccesFormRegister: true,
  isAccesFormForgotPassword: true,
  patternPasswordValidator: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$\\-!%*?&])[A-Za-z\\d@$\\-!%*?&]{8,}$',
  descriptionPatternPasswordValidator: 'Votre mot de passe doit : comporter 8 caractères minimum et contenir 1 chiffre, 1 majuscule et 1 minuscule.',
  sentry_env:'prod',
  sentry_enabled:true
};

<ng-container *ngIf="!isLoading">
  <div class="documentContainer">
    <div class="headerPart">
      <span class="title">
        <mat-icon>description</mat-icon>
        {{document.name}}
      </span>

      <div class="actions" *ngIf="document">
        <div class="download" *ngIf="hasPermissions(['can_download'])">
          <button mat-icon-button color="primary" matTooltip="Télécharger" matTooltipPosition="below"
                  (click)="downloadDocument()">
            <mat-icon>download</mat-icon>
          </button>
        </div>

        <div class="zoom" *ngIf="hasPermissions(['can_show'])">
          <button class="resetZoom" mat-mini-fab color="primary" matTooltip="Réinitialiser le zoom"
                  matTooltipPosition="below" (click)="zoomState = 1" *ngIf="zoomState !== 1">
            <mat-icon>restart_alt</mat-icon>
          </button>

          <button mat-icon-button color="primary" matTooltip="Rétrécir" matTooltipPosition="below"
                  (click)="zoomEdit('less')">
            <mat-icon>zoom_out</mat-icon>
          </button>

          <span class="zoomValue">x{{zoomState | number : '1.1-1'}}</span>

          <button mat-icon-button color="primary" matTooltip="Agrandir" matTooltipPosition="below"
                  (click)="zoomEdit('more')">
            <mat-icon>zoom_in</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="document && hasPermissions(['can_show']); else noDocument">
      <pdf-viewer [autoresize]="true"
                  [original-size]="false"
                  [(page)]="currentPage"
                  [show-all]="true"
                  [render-text]="hasPermissions(['can_download'])"
                  [src]="pdfSrc + '/' + document.path"
                  [zoom]="zoomState"
                  (pageChange)="onPageEvent($event, 'pageChange')"
                  (after-load-complete)="onPageEvent($event, 'setPageNumber')"
                  class="pdf"
                  style="display: block"
      ></pdf-viewer>
    </ng-container>

    <ng-template #noDocument>
      <div class="noDocument">
        <mat-icon>lock</mat-icon>
        <span>Vous n'avez pas l'autorisation nécessaire afin de visualiser le document</span>
      </div>
    </ng-template>


    <div class="pagesCounter" *ngIf="document && documentPage.currentPage !== 0">
      {{documentPage.currentPage}} / {{documentPage.pageNumber}}
    </div>
  </div>

  <div class="chatContainer" *ngIf="document">
    <div class="headerPart">
      <span class="title">
        <mat-icon>forum</mat-icon>
        Tchat avec le document
        <ng-container *ngIf="document.name">&laquo; {{document.name}} &raquo;</ng-container>
      </span>
    </div>
    <div class="chat" #chatBox>
      <div *ngFor="let message of messages"
           [ngClass]="{'user-message': message.from === 'user', 'ai-message': message.from === 'ai'}"
           class="messageContainer">
        <div class="message" [innerHTML]="formateResponse(message.text)">

          <span *ngIf="message.sourceDocuments ? message.sourceDocuments[0]?.metadata['loc.pageNumber'] : false"
                class="scrollToPage" (click)="scrollToPage(message)">VOIR LA PAGE</span>
        </div>
      </div>
    </div>

    <div class="chatActions">
      <input (keydown.enter)="submit()" [(ngModel)]="inputText" type="text"
             [placeholder]="hasPermissions(['can_search']) ? 'Rechercher dans le document' : 'Vous n\'avez pas l\'autorisation de rechercher dans le document'"
             [disabled]="!hasPermissions(['can_search'])">


      <button class="button-send" (click)="submit()"
              [disabled]="!hasPermissions(['can_search']) || isLoading || inputText === ''" mat-button>
        <div *ngIf="!chatIsLoading"> Envoyer</div>
        <div *ngIf="chatIsLoading" class="loader">
          <div class="dot-elastic"></div>
        </div>
      </button>

      <!--      <mk-button (click)="submit()" label="Envoyer" [loading]="chatIsLoading"-->
      <!--                 [disabled]="!hasPermissions(['can_search']) || isLoading || inputText === ''"></mk-button>-->

    </div>
  </div>

  <mk-button label="Revenir aux documents"
             assetIcon="back-arrow-blue.svg" appearance="blueBorderType"
             [clickNavigateDestination]="'document'"></mk-button>
</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatPdfRoutingModule } from './chat-pdf-routing.module';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ChatPdfComponent} from "./chat-pdf/chat-pdf.component";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {MkButtonComponent} from "@makeo-packages/mkongtools";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatExpansionModule} from "@angular/material/expansion";


@NgModule({
  declarations: [
    ChatPdfComponent
  ],
  exports: [
  ],
    imports: [
        CommonModule,
        ChatPdfRoutingModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        FormsModule,
        PdfViewerModule,
        MkButtonComponent,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatExpansionModule
    ]
})
export class ChatPdfModule { }

<div class="general">
  <div class="title">
    <div class="titlePart">
      <h1 [routerLink]="['document']">WaterDoctor</h1>
      <mk-button *ngIf="currentRouteIsChat()"
                 label="Revenir aux documents"
                 assetIcon="back-arrow-white.svg" appearance="whiteBorderType"
                 [clickNavigateDestination]="'document'"></mk-button>
    </div>
    <div class="currentUser">
      <span>{{ getCurrentUserName() }}</span>
      <div (click)="logout()">
        <img ngSrc="assets/icons/logout-white.svg" alt="logout icon" height="25" width="25" />
      </div>
    </div>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>

import { Component } from '@angular/core';
import {CustomButtonsLoginTemplate} from "@makeo-packages/mkongusers/lib/models/custom-buttons-login-template";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-custom-buttons-login',
  templateUrl: './custom-buttons-login.component.html',
  styleUrls: ['./custom-buttons-login.component.scss']
})
export class CustomButtonsLoginComponent {

  loginWithGoogle() {
    document.location.href = environment.apiAdress+'/auth/login-google';
  }

  loginWithAzure() {
    document.location.href = environment.apiAdress+'/auth/login-azure';
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-logo-login',
  templateUrl: './custom-logo-login.component.html',
  styleUrls: ['./custom-logo-login.component.scss']
})
export class CustomLogoLoginComponent {

}

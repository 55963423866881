import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard, MkongusersRoutingModule} from "@makeo-packages/mkongusers";

import {LayoutComponent} from "./layout/layout.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'document',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'document',
        loadChildren: () => import('./views/document-choice/document-choice.module').then(m => m.DocumentChoiceModule)
      },
      {
        path: 'document/chat',
        loadChildren: () => import('./views/chat-pdf/chat-pdf.module').then(m => m.ChatPdfModule)
      },
    ],
  },
];

@NgModule({
  imports: [
    MkongusersRoutingModule,
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }



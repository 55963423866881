import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ChatPdfService} from "../chat-pdf.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DocumentService} from "../../document-choice/document.service";
import {environment} from "../../../../environments/environment";
import {map, Subject, takeUntil} from "rxjs";
import {AlertCenterService} from "@makeo-packages/mkongtools";
import {PdfViewerComponent} from "ng2-pdf-viewer";

@Component({
  selector: 'app-chat-pdf',
  templateUrl: './chat-pdf.component.html',
  styleUrls: ['./chat-pdf.component.scss'],
})
export class ChatPdfComponent implements OnInit, OnDestroy {

  public pdfSrc = environment.pdfAdress
  public messages: { from: string, text: string, sourceDocuments?: any[] }[] = [];
  public inputText = '';
  public isLoading = false;
  public chatIsLoading = false;
  public document?: any;
  public zoomState: number = 1;
  public documentPage = {currentPage: 0, pageNumber: 0}
  public currentPage: number = 1;

  private _unsubscribeAll: Subject<any> = new Subject();

  @ViewChild('chatBox') private chatBox: ElementRef | undefined;
  @ViewChild(PdfViewerComponent) private pdfComponent: PdfViewerComponent | undefined;

  constructor(
    private _formBuilder: FormBuilder,
    private chatService: ChatPdfService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public documentsService: DocumentService,
    private alertCenterService: AlertCenterService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.activatedRoute.params.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: (data) => {
        this.getDocument(data['id']);
      },
      error: (err) => {
        this.isLoading = false;
        console.error(err);
        this.alertCenterService.error('Erreur lors de la récupération du document');
        this.router.navigate(['document']);
      }
    });
    this.messages.push({from: 'ai', text: 'Quelle question souhaitez-vous posez à propos de ce document ?'});
  }

  getDocument(id: number) {
    this.documentsService.getDocument(id).pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: doc => {
        this.document = doc;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.alertCenterService.error('Erreur lors de la récupération du document');
        this.router.navigate(['document']);
      }
    })
  }

  submit() {
    if (this.inputText !== '' && this.hasPermissions(['can_search'])) {
      this.chatIsLoading = true
      this.messages.push({from: 'user', text: this.inputText});
      let history: {from:string, text:string}[] = []
      this.messages.forEach(msg=> {
        history.push({from: msg.from,text: msg.text});
      })
      setTimeout(() => {
        this.scrollToBottom();
      });

      this.chatService.getAnswer({
        question: this.inputText,
        document: this.document.id,
        history : this.messages
      }).pipe(takeUntil(this._unsubscribeAll)).subscribe({
        next: data => {
          // this.response = data.text
          this.messages.push({from: 'ai', text: data.text, sourceDocuments:data?.sourceDocuments});

          setTimeout(() => {
            this.scrollToBottom();
          });
          this.chatIsLoading = false;
        },
        error: () => {
          this.chatIsLoading = false;
          this.alertCenterService.error('Erreur lors de la récupération des données, merci de contacter le support');
        }
      })
      this.inputText = '';
    }
  }

  zoomEdit(action: 'more' | 'less') {
    const increment = .1;
    switch (action) {
      case "more": {
        if (this.zoomState < 4) {
          this.zoomState = this.zoomState + increment;
        }
        break;
      }
      case "less": {
        if (this.zoomState > .2) {
          this.zoomState = this.zoomState - increment;
        }
        break;
      }
    }
  }

  onPageEvent(event: any, type: 'setPageNumber' | 'pageChange') {
    switch (type) {
      case "setPageNumber": {
        this.documentPage.pageNumber = event.numPages;
        break;
      }
      case "pageChange": {
        this.documentPage.currentPage = event;
        break;
      }
    }
  }

  scrollToBottom(): void {
    if (this.chatBox) {
      try {
        this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
      } catch (err) {
      }
    }
  }

  downloadDocument() {
    if (this.hasPermissions(['can_download'])) {
      this.documentsService.downloadDocument(this.document.id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          const filename = this.document.name+'.pdf'
          this.downloadDataToFile(res.body,filename);
      },);
    }
  }

  scrollToPage(message: any) {
    if (message?.sourceDocuments[0]?.metadata['loc.pageNumber']) {
      this.currentPage = message?.sourceDocuments[0].metadata['loc.pageNumber'];
    }
  }

  hasPermissions(permissionsToCheck: ('can_show'|'can_search'|'can_download')[] = []): boolean {
    // return true if all permission in parameter array are true
    return permissionsToCheck.every(perm => this.document.permissions[0][perm] === true);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  private extractFilenameInContentDisposition(contentDisposition: string): string {
    return contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .replace(/"/g, '')
      .trim();
  }

  private downloadDataToFile(data:Blob, filename:string) {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(data);

    a.href = objectUrl;
    a.download = filename;
    a.click();

    URL.revokeObjectURL(objectUrl);

  }

  public handleDownloadError(filename: string | null = null) {
    this.alertCenterService.error(`Impossible de télécharger le fichier ${filename} `);
  }

  formateResponse(text: string) {
    // la ligne sous dessous sert à retirer le premier saut de ligne ensuite on les transformes tous
    let textFormate = text.replace(/\n/, '');
    return textFormate.replace(/\n/g, '<br>');

  }
}

import { Injectable } from '@angular/core';
import { RestApiService } from '@makeo-packages/mkongtools';
import { map, Observable } from 'rxjs';
import { Document } from '../../models/document';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  apiEndpoint: string = 'document';

  constructor(protected api: RestApiService, private http: HttpClient) {}

  public getDocuments(): Observable<any>{
    return this.api.get(this.apiEndpoint).pipe(map(
      (res) => {
        return res.datas.map((data: any) => {
          return new Document().deserialize(data);
        });
      }
    ));
  }

  public getDocument(id: any): Observable<any>{
    return this.api.get(this.apiEndpoint, id).pipe(map(
      (res) => {
        return res.datas;
      }
    ));
  }


  //It can be topicId or SubtopicId
  public getDocumentsByTopic(topicId: any): Observable<any>{
    return this.api.get(this.apiEndpoint + '/topic/' + topicId).pipe(map(
      (res) => {
        return res.datas.map((data: any) => {
          return new Document().deserialize(data);
        });
      }
    ));
  }

  public downloadDocument(id: any): Observable<any>{
    return this.http.get(environment.apiAdress +"/"+ this.apiEndpoint+"/"+id+"/download",{
      responseType: 'blob',
      observe: 'response',
    });
  }

}
